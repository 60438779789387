<template>
  <ValidationObserver
    ref="validationObserver"
  >
    <form
      v-if="isFormAvailable()"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="submitAccountForm"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="openModalForRemove"
          :disabled="isEnabled"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Accounts
        </span>
        <span v-if="!isEdit">
          / Add Account
        </span>
        <span
          v-else
        >
          / Edit {{ formData.name }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Edit Account' : 'Add Account' }}
      </div>
      <div>
        <Tabs
          :smallStyle="true"
          :isLong="true"
        >
          <Tab
            name="Account"
            :selected="true"
            :className="[!validate ? 'error-tab' : 'tab']"
          >
            <div>
              <MaterialInput
                v-model="formData.name"
                v-bind="formFields.name"
              />
            </div>
            <div>
              <MaterialInput
                v-model="formData.industry"
                v-bind="formFields.industry"
              />
            </div>
            <div class="flex">
              <div class="country-field">
                <MaterialInput
                  v-model="formData.country"
                  v-bind="formFields.country"
                />
              </div>
              <div class="country-field">
                <MaterialSelect
                  v-model="formData.state"
                  v-bind="formFields.state"
                  :options="states"
                />
              </div>
              <div class="country-field">
                <MaterialInput
                  v-model="formData.city"
                  v-bind="formFields.city"
                />
              </div>
            </div>
            <div>
              <MaterialInput
                v-model="formData.streetAddress"
                v-bind="formFields.streetAddress"
              />
            </div>
            <div>
              <MaterialSelect
                v-model="formData.timezone"
                v-bind="formFields.timezone"
                :options="timezones"
              />
            </div>
            <div>
              <MaterialInput
                v-model="formData.suite"
                v-bind="formFields.suite"
              />
            </div>
            <div>
              <MaterialInput
                v-model="formData.zip"
                v-bind="formFields.zip"
              />
            </div>
            <div>
              <MaterialInput
                v-model="formData.phone"
                v-bind="formFields.phone"
              />
            </div>
            <div>
              <MaterialInput
                v-model="formData.website"
                v-bind="formFields.website"
              />
            </div>
            <div>
              <MaterialSelect
                v-model="formData.subscription.type"
                v-bind="formFields.subscriptionType"
                :disabled="!isSubscriptionTypeAvailable()"
                :options="subscriptionTypes"
              />
            </div>
            <div>
              <MaterialSelect
                v-if="isSubscriptionPlanVisible()"
                v-model="formData.subscription.plan"
                v-bind="formFields.subscriptionPlan"
                :disabled="!isSubscriptionPlanAvailable()"
                :options="subscriptionPlans"
              />
              <MaterialCalendar
                v-model="formData.subscription.finishDate"
                v-bind="formFields.subscriptionFinishDate"
                :disabled="!isSubscriptionFinishDateAvailable()"
                blockClassName="group"
              />
            </div>
            <MaterialInput
              v-if="isEdit"
              v-model="formData.limelightSegmentId"
              v-bind="formFields.limelightSegmentId"
            />
            <div
              v-if="isEdit"
              class="wowza"
            >
              <p>Wowza application name:</p>
              <p>{{ wowzaAppName }}</p>
            </div>
            <div>
              <MaterialSelect
                v-if="isEdit"
                :value="formData.wowzaServer"
                v-bind="formFields.wowzaServer"
                :disabled="isEdit"
                :options="prepareWowzaServer"
              />
              <MaterialSelect
                v-else
                v-model="formData.wowzaServer"
                v-bind="formFields.wowzaServer"
                :options="wowzaServerList"
              />
            </div>
            <MaterialInput
              v-if="isEdit"
              v-model="formData.analyticId"
              v-bind="formFields.analyticId"
              placeholder="If empty, the default analytics entity id will be used"
            />
            <div class="btn-container">
              <Button
                :title="'Save'"
                :classType="'primary'"
                :type="'submit'"
              />
            </div>
          </Tab>
          <Tab
            name="Account Features"
            :selected="false"
          >
            <div class="check-wrapper">
              <MaterialCheckbox
                :checked="formData.tvAppsEnabled"
                :label="'TV Apps Distribution'"
                :toggle="toggleTvAppsEnabled"
              />
            </div>
            <div class="check-wrapper">
              <div class="toggle-wrapper dynamic">
                <div class="toggle-title">
                  Video Apps Engine
                </div>
                <ToggleButton
                  v-model="formData.hasVideoAppsEngine"
                  color="#2591CF"
                  :sync="true"
                  :labels="false"
                />
              </div>
            </div>
            <MediaTagManagement
              v-if="contributorAccountsOptions.length"
              :selectedValue="formData.contributorAccounts"
              :label="'Specify Contributor Accounts'"
              :tagPlaceholder="''"
              :searchable="true"
              :onChange="onContrAccChange"
              :customOptions="contributorAccountsOptions"
              :isAudio="true"
              :isJustSearch="true"
            />
            <div
              v-if="masterAccounts.length && isEdit"
              class="localization-warning-wrapper"
            >
              <inline-svg
                :src="iconWarning"
                class="svg-icon"
              >
              </inline-svg>
              <span class="localization-warning">
                This Account has been selected as a Contributor
                Account for the following Master Accounts:
                <ul class="master-accs">
                  <li
                    v-for="(acc) in masterAccounts"
                    :key="acc.id"
                  >
                    {{ acc.name }}
                  </li>
                </ul>
              </span>
            </div>
          </Tab>
          <Tab
            name="Integrations"
            :selected="false"
          >
            <div class="check-wrapper">
              <MaterialCheckbox
                :checked="formData.brushfireEnabled"
                :label="'Enable Brushfire Integration'"
                :toggle="toggleBrushfireEnabled"
              />
            </div>
          </Tab>
          <Tab
            v-if="formData.hasVideoAppsEngine"
            name="Video Apps Engine"
            :selected="false"
            :className="[tabErrors.videoAppsEngine ? 'error-tab' : 'tab']"
          >
            <div class="themes-wrapper">
              <div class="check-theme-label">
                Select Navigation Layout
                <span style="color: red">*</span>
              </div>
              <div class="check-themes-wrapper">
                <MaterialCheckbox
                  :toggle="toggleChangeTopTheme"
                  :label="'Top Navigation'"
                  :checked="formData.isTopTheme"
                  :error="errorTopCheckbox"
                />
                <MaterialCheckbox
                  :toggle="toggleChangeLeftTheme"
                  :label="'Left Navigation'"
                  :checked="formData.isLeftTheme"
                  :error="errorLeftCheckbox"
                />
              </div>
            </div>
            <div class="toggle-wrapper permission">
              <div class="toggle-title">
                Authentication Permissions
              </div>
              <ToggleButton
                v-model="formData.hasPermissions"
                color="#2591CF"
                :sync="true"
                :labels="false"
              />
            </div>
            <MaterialSelect
              v-if="formData.hasPermissions"
              v-model="formData.authPermission"
              v-bind="formFields.authPermission"
              :options="authPermissions"
              :withEmptyField="false"
            />
            <div
              v-if="tabErrors.videoAppsEngine && isCreCredentialsError"
              class="error-credentials"
            >
              <inline-svg
                :src="iconRedError"
                class="svg-icon"
              >
              </inline-svg>
              <span style="margin-left: 10px">
                Credentials are invalid
              </span>
            </div>
            <div v-if="formData.hasPermissions && formData.authPermission === 'okta'">
              <MaterialInput
                v-model="formData.oktaHost"
                v-bind="formFields.oktaHost"
                :redLine="tabErrors.videoAppsEngine && isCreCredentialsError"
                :requiredFromBack="true"
              />
              <MaterialInput
                v-model="formData.oktaApiToken"
                v-bind="formFields.oktaApiToken"
                :redLine="tabErrors.videoAppsEngine && isCreCredentialsError"
                :requiredFromBack="true"
              />
            </div>
            <div v-if="formData.hasPermissions && formData.authPermission === 'one_login'">
              <MaterialInput
                v-model="formData.oneLoginSubDomain"
                v-bind="formFields.oneLoginSubDomain"
                :redLine="tabErrors.videoAppsEngine && isCreCredentialsError"
                :requiredFromBack="true"
              />
              <MaterialInput
                v-model="formData.oneLoginClientId"
                v-bind="formFields.oneLoginClientId"
                :redLine="tabErrors.videoAppsEngine && isCreCredentialsError"
                :requiredFromBack="true"
              />
              <MaterialInput
                v-model="formData.oneLoginClientSecret"
                v-bind="formFields.oneLoginClientSecret"
                :redLine="tabErrors.videoAppsEngine && isCreCredentialsError"
                :requiredFromBack="true"
              />
              <MaterialSelect
                v-model="formData.oneLoginBackUpUserPoolId"
                v-bind="formFields.oneLoginBackUpUserPoolId"
                :options="cognitoUserPools"
              />
              <MaterialInput
                v-model="formData.oneLoginJWKsURL"
                v-bind="formFields.oneLoginJWKsURL"
                :rules="`${formData.oneLoginBackUpUserPoolId ? 'required' : ''}|max:255|min:1`"
              />
            </div>
            <div v-if="formData.hasPermissions && formData.authPermission === 'cognito'">
              <MaterialSelect
                v-model="formData.identityId"
                v-bind="formFields.identityId"
                :options="cognitoUserPools"
              />
              <MaterialInput
                v-model="formData.cognitoJWKsURL"
                v-bind="formFields.cognitoJWKsURL"
              />
            </div>
            <div v-if="formData.hasPermissions && formData.authPermission === 'keycloak'">
              <MaterialInput
                v-model="formData.keycloakHost"
                v-bind="formFields.keycloakHost"
              />
              <MaterialInput
                v-model="formData.keycloakClientId"
                v-bind="formFields.keycloakClientId"
              />
              <MaterialInput
                v-model="formData.keycloakClientSecret"
                v-bind="formFields.keycloakClientSecret"
              />
              <MaterialInput
                v-model="formData.keycloakJWKsURL"
                v-bind="formFields.keycloakJWKsURL"
              />
              <MaterialInput
                v-model="formData.keycloakIdentityId"
                v-bind="formFields.keycloakIdentityId"
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </form>
    <DeleteAccountModal
      ref="modal"
      v-model="isOpenDelete"
      :modal="isOpenDelete"
      :bodyMessage="bodyMessage"
      :title="titleRemoveAcc"
      :isDeleteAcc="true"
      :name="companyName"
      :accId="accId"
      :onClose="closeModal"
      :isEditDelete="true"
    />
    <NotificationModal
      v-if="isEdit"
      ref="modal"
      v-model="isModalDisabled"
      :modal="isModalDisabled"
      :title="'Disable Video Apps Engine'"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Ok'"
      :bodyMessage="'Disabling Video Apps Engine will break applications for this account'"
      :onAccept="onSaveDisabled"
      :onClose="onCloseDisabled"
      :isOrangeCancel="true"
    />
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import {
  ADD_ACCOUNT_REQUEST,
  EDIT_ACCOUNT_REQUEST,
  GET_ACCOUNT_REQUEST,
  GET_CONTRIBUTOR_ACCOUNTS_ADD_REQUEST,
  GET_CONTRIBUTOR_ACCOUNTS_EDIT_REQUEST,
  GET_MASTER_ACCOUNTS_REQUEST,
  GET_STATE_REQUEST,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_TYPE_REQUEST,
  GET_COGNITO_USER_POOLS_REQUEST,
} from '../../store/actions/accountsActions/accounts';
import { GET_ALL_TIMEZONE_REQUEST } from '../../store/actions/timezoneActions/timezone';
import { GET_ALL_SERVERS } from '../../store/actions/servers/servers';
import { SETUP_TV_ENGINE_REQUEST } from '../../store/actions/cmsActions/cmsActions';

import BaseForm from './BaseForm.vue';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import MaterialCalendar from '../inputs/MaterialCalendar.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import Button from '../common/Button.vue';
import DeleteAccountModal from '../DeleteAccountModal.vue';

import CONFIG from '../../constants/config';
import NotificationModal from '../NotificationModal.vue';
import MediaTagManagement from '../media/MediaTagManagement.vue';
import iconWarning from '../../assets/icons/icon-warning-localization.svg';
import iconRedError from '../../assets/icons/icon-red-circle-warning.svg';

export default {
  name: 'AccountForm',
  components: {
    NotificationModal,
    MaterialCalendar,
    DeleteAccountModal,
    MaterialInput,
    MaterialSelect,
    MaterialCheckbox,
    MediaTagManagement,
    Button,
    Tabs,
    Tab,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'account',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconWarning,
      iconRedError,
      isHasId: true,
      isOpenDelete: false,
      isModalDisabled: false,
      isDis: false,
      bodyMessage: '',
      titleRemoveAcc: '',
      companyName: '',
      accId: '',
      isEnabled: true,
      contributorAccountsOptions: [],
      isSubmited: false,
      themesError: '',
      isCreCredentialsError: false,
      themeErrorType: '',
      authPermissions: [
        {
          code: 'okta',
          name: 'Okta',
        },
        {
          code: 'one_login',
          name: 'OneLogin',
        },
        {
          code: 'cognito',
          name: 'Cognito',
        },
        {
          code: 'keycloak',
          name: 'Keycloak',
        },
      ],
      formData: {
        isTopTheme: true,
        isLeftTheme: false,
        authPermission: '',
        oktaApiToken: '',
        oktaHost: '',
        oneLoginClientId: '',
        oneLoginClientSecret: '',
        oneLoginSubDomain: '',
        analyticId: '',
        hasVideoAppsEngine: false,
        hasPermissions: false,
        streetAddress: '',
        city: '',
        name: '',
        country: '',
        industry: '',
        phone: '',
        zip: '',
        state: '',
        suite: '',
        website: '',
        timezone: '',
        subscription: {
          type: '',
          plan: '',
          finishDate: new Date(),
        },
        limelightSegmentId: null,
        wowzaServer: null,
        tvAppsEnabled: false,
        brushfireEnabled: false,
        contributorAccounts: [],
        identityId: '',
        oneLoginBackUpUserPoolId: '',
        oneLoginJWKsURL: '',
        cognitoJWKsURL: '',
        keycloakHost: '',
        keycloakClientId: '',
        keycloakClientSecret: '',
        keycloakJWKsURL: '',
        keycloakIdentityId: '',
      },
      tabErrors: {
        videoAppsEngine: false,
      },
      prepareWowzaServer: [],
      serverListMaxPage: 1,
      subscriptionPlanEditable: true,
      wowzaAppName: '',
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        oktaHost: {
          type: 'text',
          name: 'oktaHost',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        oktaApiToken: {
          type: 'text',
          name: 'oktaApiToken',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        oneLoginSubDomain: {
          type: 'text',
          name: 'oneLoginSubDomain',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        oneLoginClientId: {
          type: 'text',
          name: 'oneLoginClientId',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        oneLoginClientSecret: {
          type: 'text',
          name: 'oneLoginClientSecret',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        authPermission: {
          type: 'text',
          name: 'authPermission',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        city: {
          type: 'text',
          name: 'city',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        zip: {
          type: 'text',
          name: 'zip',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        streetAddress: {
          type: 'text',
          name: 'streetAddress',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        suite: {
          type: 'text',
          name: 'suite',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        website: {
          type: 'text',
          name: 'website',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        state: {
          name: 'state',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        phone: {
          type: 'text',
          name: 'phone',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        country: {
          type: 'text',
          name: 'country',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        industry: {
          type: 'text',
          name: 'industry',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        timezone: {
          type: 'text',
          name: 'timezone',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        subscriptionType: {
          name: 'subscriptionType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        subscriptionPlan: {
          name: 'subscriptionPlan',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        subscriptionFinishDate: {
          type: 'date',
          name: 'subscriptionFinishDate',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        limelightSegmentId: {
          type: 'number',
          name: 'Limelight Segment',
          formPrefix: this.formPrefix,
          rules: {
            min: 1,
            max_value: 4294967295,
          },
        },
        wowzaServer: {
          type: 'text',
          name: 'wowzaServer',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        analyticId: {
          type: 'text',
          name: 'analyticId',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        oneLoginBackUpUserPoolId: {
          name: 'oneLoginBackUpUserPoolId',
          key: 'oneLoginBackUpUserPoolId',
          formPrefix: this.formPrefix,
        },
        identityId: {
          name: 'identityId',
          key: 'identityId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        oneLoginJWKsURL: {
          type: 'text',
          name: 'JWKsURL',
          formPrefix: this.formPrefix,
        },
        cognitoJWKsURL: {
          type: 'text',
          name: 'JWKsURL',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
            min: 1,
          },
        },
        keycloakHost: {
          type: 'text',
          name: 'keycloakHost',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        keycloakClientId: {
          type: 'text',
          name: 'keycloakClientId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        keycloakClientSecret: {
          type: 'text',
          name: 'keycloakClientSecret',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        keycloakJWKsURL: {
          type: 'text',
          name: 'keycloakJWKsURL',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        keycloakIdentityId: {
          type: 'text',
          name: 'keycloakIdentityId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        city: [
          `${this.formPrefix}.city`,
        ],
        zip: [
          `${this.formPrefix}.zip`,
        ],
        streetAddress: [
          `${this.formPrefix}.streetAddress`,
        ],
        suite: [
          `${this.formPrefix}.suite`,
        ],
        website: [
          `${this.formPrefix}.website`,
        ],
        state: [
          `${this.formPrefix}.state`,
        ],
        phone: [
          `${this.formPrefix}.phone`,
        ],
        country: [
          `${this.formPrefix}.country`,
        ],
        industry: [
          `${this.formPrefix}.industry`,
        ],
        timezone: [
          `${this.formPrefix}.timezone`,
        ],
        limelightSegmentId: [
          `${this.formPrefix}.limelightSegmentId`,
        ],
        subscriptionType: [
          `${this.formPrefix}.subscription.type`,
        ],
        subscriptionPlan: [
          `${this.formPrefix}.subscription.plan`,
        ],
        subscriptionFinishDate: [
          `${this.formPrefix}.subscription.finishDate`,
        ],
        wowzaServer: [
          `${this.formPrefix}.wowzaServer`,
        ],
        analyticId: [
          `${this.formPrefix}.analyticId`,
        ],
        oktaHost: [
          `${this.formPrefix}.form_errors`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_ACCOUNT_REQUEST : ADD_ACCOUNT_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editAccountMessage
        : CONFIG.successMessages.addAccountMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      states: (state) => state.accounts.states,
      masterAccounts: (state) => state.accounts.masterAccounts,
      timezones: (state) => state.timezone.timezones,
      subscriptionPlans: (state) => state.accounts.subscriptionPlans,
      subscriptionTypes: (state) => state.accounts.subscriptionTypes,
      cognitoUserPools: (state) => state.accounts.cognitoUserPools,
      servers: (state) => state.servers.servers,
    }),
    wowzaServerList() {
      return this.servers.map((item) => ({ code: item.id, name: item.name }));
    },
    validate() {
      return !((!this.formData.name
          || !this.formData.industry
          || !this.formData.country
          || !this.formData.state
          || !this.formData.city
          || !this.formData.zip
          || !this.formData.website
          || !this.formData.streetAddress
          || !this.formData.suite
          || !this.formData.phone
          || !this.formData.subscription.type
          || !this.formData.subscription.finishDate
          || !this.formData.wowzaServer
          || !this.formData.timezone)
        && this.isSubmited);
    },
    errorTopCheckbox() {
      if (!this.isEdit) {
        return this.themesError;
      }
      if (this.themeErrorType === 'top_navigation' && this.themesError) {
        return this.themesError;
      }
      return '';
    },
    errorLeftCheckbox() {
      if (!this.isEdit) {
        return this.themesError;
      }
      if (this.themeErrorType === 'left_navigation' && this.themesError) {
        return this.themesError;
      }
      return '';
    },
  },
  mounted() {
    this.$store.dispatch(GET_STATE_REQUEST);
    this.$store.dispatch(GET_SUBSCRIPTION_TYPE_REQUEST);
    this.$store.dispatch(GET_SUBSCRIPTION_PLAN_REQUEST);
    this.$store.dispatch(GET_ALL_TIMEZONE_REQUEST);
    this.$store.dispatch(GET_COGNITO_USER_POOLS_REQUEST);

    if (!this.isEdit) {
      this.getServersList();

      this.$store.dispatch(GET_CONTRIBUTOR_ACCOUNTS_ADD_REQUEST).then((res) => {
        this.contributorAccountsOptions = res.data.map((item) => ({
          name: item.name,
          code: item.id,
        }));
      });
    }

    if (this.isEdit && this.$route.params.id) {
      const {
        errorMessages,
        statuses,
      } = CONFIG;
      this.$store.dispatch(GET_ACCOUNT_REQUEST, this.$route.params.id)
        .then((res) => {
          this.initFormData(res);
          this.$store.dispatch(GET_MASTER_ACCOUNTS_REQUEST, this.$route.params.id);

          const params = {
            accountId: this.$route.params.id,
          };
          this.$store.dispatch(GET_CONTRIBUTOR_ACCOUNTS_EDIT_REQUEST, params)
            .then((res) => {
              this.contributorAccountsOptions = res.data.map((item) => ({
                name: item.name,
                code: item.id,
              }));
            });
        })
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.isHasId = false;
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    }
  },
  methods: {
    onContrAccChange(value) {
      this.formData.contributorAccounts = value;
    },
    onCloseDisabled() {
      this.isModalDisabled = false;
    },
    onSaveDisabled() {
      this.isDis = true;
      this.submit();
      this.isModalDisabled = false;
    },
    isFormAvailable() {
      return this.states
        && this.states.length
        && this.timezones
        && this.timezones.length
        && this.isHasId;
    },
    enableTVEngine() {
      this.$store.dispatch(SETUP_TV_ENGINE_REQUEST, { accountId: this.$route.params.id })
        .then(() => {
          this.formData.hasVideoAppsEngine = true;
          this.$toasted.global.success({
            message: 'Video Apps Engine has been successfully set up',
          });
        });
    },
    isSubscriptionTypeAvailable() {
      return !this.isEdit;
    },
    isSubscriptionFinishDateAvailable() {
      return this.formData.subscription.type === CONFIG.subscriptionTypes.offline;
    },
    isSubscriptionPlanAvailable() {
      return this.formData.subscription.type === CONFIG.subscriptionTypes.online
        && this.subscriptionPlanEditable;
    },
    isSubscriptionPlanVisible() {
      return this.formData.subscription.type === CONFIG.subscriptionTypes.online;
    },
    initFormData(account) {
      this.isEnabled = account.enabled;
      this.accId = account.id;
      this.formData.streetAddress = account.streetAddress;
      this.formData.city = account.city;
      this.formData.name = account.name;
      this.formData.country = account.country;
      this.formData.industry = account.industry;
      this.formData.phone = account.phone;
      this.formData.zip = account.zip;
      this.formData.state = account.state;
      this.formData.suite = account.suite;
      this.formData.website = account.website;
      this.formData.timezone = account.timezone;
      this.wowzaAppName = account.wowzaApp.name;
      this.formData.subscription.type = account.subscription.type;
      this.formData.subscription.plan = account.subscription.plan;
      this.formData.limelightSegmentId = account.limelightSegmentId;
      this.formData.wowzaServer = account.wowzaServer.id;
      this.formData.tvAppsEnabled = account.tvAppsEnabled;
      this.formData.hasVideoAppsEngine = account.hasVideoAppsEngine;
      this.formData.analyticId = account.analyticId;
      this.formData.brushfireEnabled = account.brushfireProtectionEnabled;
      this.formData.hasPermissions = account.videoAppsEnginePermissionEnabled;
      this.formData.contributorAccounts = account.contributorAccounts;

      if (account.vaeConfiguration) {
        this.formData.isTopTheme = account.vaeConfiguration.availableNavigationLayouts.find((item) => item === 'top_navigation');
        this.formData.isLeftTheme = account.vaeConfiguration.availableNavigationLayouts.find((item) => item === 'left_navigation');
      }

      if (account.authenticationProvider) {
        this.formData.authPermission = account.authenticationProvider.type;
        this.formData.oneLoginSubDomain = account.authenticationProvider.subDomain;
        this.formData.oneLoginClientId = account.authenticationProvider.clientId;
        this.formData.oneLoginClientSecret = account.authenticationProvider.clientSecret;
        this.formData.oktaHost = account.authenticationProvider.host;
        this.formData.oktaApiToken = account.authenticationProvider.apiToken;

        if (account.authenticationProvider.type === 'one_login') {
          this.formData.oneLoginBackUpUserPoolId = account.authenticationProvider.identityId;
          this.formData.oneLoginJWKsURL = account.authenticationProvider.jwksUrl;
        }

        if (account.authenticationProvider.type === 'cognito') {
          this.formData.identityId = account.authenticationProvider.identityId;
          this.formData.cognitoJWKsURL = account.authenticationProvider.jwksUrl;
        }

        if (account.authenticationProvider.type === 'keycloak') {
          this.formData.keycloakHost = account.authenticationProvider.host;
          this.formData.keycloakClientId = account.authenticationProvider.clientId;
          this.formData.keycloakClientSecret = account.authenticationProvider.clientSecret;
          this.formData.keycloakJWKsURL = account.authenticationProvider.jwksUrl;
          this.formData.keycloakIdentityId = account.authenticationProvider.identityId;
        }
      }

      if (this.isEdit) {
        this.prepareWowzaServer.push({
          code: account.wowzaServer.id,
          name: account.wowzaServer.name,
        });
      }

      const finishDate = moment(account.subscription.finishDate, 'YYYY-MM-DD');
      if (finishDate.isValid()) {
        this.formData.subscription.finishDate = finishDate.toDate();
      }

      this.subscriptionPlanEditable = !account.subscription.hasOnlineSubscription
        && !account.subscription.hasPendingSession;
    },
    getRequestData() {
      this.isSubmited = true;
      if (this.isEdit && !this.formData.hasVideoAppsEngine && !this.isDis) {
        this.isModalDisabled = true;
        return null;
      }

      const formData = {
        streetAddress: this.formData.streetAddress,
        timezone: this.formData.timezone,
        city: this.formData.city,
        name: this.formData.name,
        country: this.formData.country,
        industry: this.formData.industry,
        phone: this.formData.phone,
        zip: this.formData.zip,
        state: this.formData.state,
        suite: this.formData.suite,
        website: this.formData.website,
        subscription: {},
        limelightSegmentId: this.formData.limelightSegmentId,
        tvAppsEnabled: this.formData.tvAppsEnabled,
        brushfireProtectionEnabled: this.formData.brushfireEnabled,
        hasVideoAppsEngine: this.formData.hasVideoAppsEngine,
        videoAppsEnginePermissionEnabled: this.formData.hasPermissions,
        contributorAccounts: this.formData.contributorAccounts,
      };

      if (!this.formData.hasVideoAppsEngine) {
        delete formData.videoAppsEnginePermissionEnabled;
      }

      if (this.formData.hasVideoAppsEngine) {
        formData.vaeConfiguration = {
          availableNavigationLayouts: [],
        };

        if (this.formData.isTopTheme) {
          formData.vaeConfiguration.availableNavigationLayouts.push('top_navigation');
        }

        if (this.formData.isLeftTheme) {
          formData.vaeConfiguration.availableNavigationLayouts.push('left_navigation');
        }
      }

      if (this.formData.hasVideoAppsEngine && this.formData.hasPermissions) {
        formData.authenticationProvider = {
          type: this.formData.authPermission,
        };

        if (this.formData.authPermission === 'one_login') {
          formData.authenticationProvider.subDomain = this.formData.oneLoginSubDomain;
          formData.authenticationProvider.clientId = this.formData.oneLoginClientId;
          formData.authenticationProvider.clientSecret = this.formData.oneLoginClientSecret;
          formData.authenticationProvider.identityId = this.formData
            .oneLoginBackUpUserPoolId;
          formData.authenticationProvider.jwksUrl = this.formData.oneLoginJWKsURL;
        }

        if (this.formData.authPermission === 'okta') {
          formData.authenticationProvider.host = this.formData.oktaHost;
          formData.authenticationProvider.apiToken = this.formData.oktaApiToken;
        }

        if (this.formData.authPermission === 'cognito') {
          formData.authenticationProvider.identityId = this.formData.identityId;
          formData.authenticationProvider.jwksUrl = this.formData.cognitoJWKsURL;
        }

        if (this.formData.authPermission === 'keycloak') {
          formData.authenticationProvider.host = this.formData.keycloakHost;
          formData.authenticationProvider.clientId = this.formData.keycloakClientId;
          formData.authenticationProvider.clientSecret = this.formData.keycloakClientSecret;
          formData.authenticationProvider.jwksUrl = this.formData.keycloakJWKsURL;
          formData.authenticationProvider.identityId = this.formData.keycloakIdentityId;
        }
      }

      if (!this.isEdit) {
        delete formData.limelightSegmentId;
        formData.wowzaServer = this.formData.wowzaServer;
      }

      if (this.isSubscriptionTypeAvailable()) {
        formData.subscription.type = this.formData.subscription.type;
      }

      if (this.isSubscriptionPlanAvailable()) {
        formData.subscription.plan = this.formData.subscription.plan;
      }

      if (this.isSubscriptionFinishDateAvailable()) {
        formData.subscription.finishDate = this.setAsDate(this.formData.subscription.finishDate);
      }

      if (this.isEdit) {
        formData.analyticId = !this.formData.analyticId ? `bc.${this.$route.params.id}` : this.formData.analyticId;
      }

      return {
        accountId: this.$route.params.id,
        data: formData,
      };
    },
    toggleTvAppsEnabled() {
      this.formData.tvAppsEnabled = !this.formData.tvAppsEnabled;
    },
    togglePermissions() {
      this.formData.hasPermissions = !this.formData.hasPermissions;
    },
    toggleVideoAppsEngine() {
      this.formData.hasVideoAppsEngine = !this.formData.hasVideoAppsEngine;
    },
    toggleBrushfireEnabled() {
      this.formData.brushfireEnabled = !this.formData.brushfireEnabled;
    },
    setAsDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    onSubmitSuccess() {
      this.isSubmited = false;
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/accounts');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const credentialsError = error.form_errors
        && error.form_errors.errors
        && error.form_errors.errors[0].error === 'AUTHENTICATION_PROVIDER_CREDENTIALS_ARE_INVALID';

      if (!this.isEdit) {
        const themesError = error.form_errors
          && error.form_errors.children
          && error.form_errors.children.vaeConfiguration
          && error.form_errors.children.vaeConfiguration.children
          && error.form_errors.children.vaeConfiguration.children.availableNavigationLayouts
          && error.form_errors.children.vaeConfiguration.children.availableNavigationLayouts.errors[0].error === 'TOO_FEW_ERROR';

        if (themesError) {
          this.tabErrors.videoAppsEngine = true;
          this.themesError = 'At least one option is required';
        }
      } else {
        const themesError = error.form_errors
          && error.form_errors.children
          && error.form_errors.children.vaeConfiguration
          && error.form_errors.children.vaeConfiguration.children
          && error.form_errors.children.vaeConfiguration.children.availableNavigationLayouts
          // eslint-disable-next-line max-len
          && error.form_errors.children.vaeConfiguration.children.availableNavigationLayouts.errors[error.form_errors.children.vaeConfiguration.children.availableNavigationLayouts.errors.length > 1 ? 1 : 0];

        if (themesError && themesError.error === 'NO_REQUIRED_CHOICES_ERROR') {
          this.tabErrors.videoAppsEngine = true;
          this.themeErrorType = themesError.invalid_value && themesError.invalid_value[0];
          this.themesError = `The ${this.themeErrorType === 'left_navigation' ? 'Left' : 'Top'} Navigation Layout is in use and cannot be unchecked`;
        }
      }

      if (credentialsError) {
        this.tabErrors.videoAppsEngine = true;
        this.isCreCredentialsError = true;
      }
    },
    getServersList() {
      const props = {
        allItems: true,
      };
      this.$store.dispatch(GET_ALL_SERVERS, props);
    },
    backTo() {
      this.$router.push('/settings/accounts');
    },
    openModalForRemove() {
      this.isOpenDelete = true;
      this.bodyMessage = 'This action cannot be undone. To confirm, please enter the Company Name of the account you wish to delete:';
      this.titleRemoveAcc = 'Delete Account';
      this.companyName = this.formData.name;
    },
    closeModal() {
      this.isOpenDelete = false;
    },
    submitAccountForm() {
      this.isSubmited = true;
      this.tabErrors.videoAppsEngine = false;
      this.$refs.validationObserver.handleSubmit(this.submit);
    },
    toggleChangeTopTheme(isChecked) {
      if (isChecked !== this.formData.isTopTheme) {
        this.formData.isTopTheme = isChecked ? 1 : 0;
        this.themesError = '';
        this.themeErrorType = '';
      }
    },
    toggleChangeLeftTheme(isChecked) {
      if (isChecked !== this.formData.isLeftTheme) {
        this.formData.isLeftTheme = isChecked ? 1 : 0;
        this.themesError = '';
        this.themeErrorType = '';
      }
    },
  },
};
</script>

<style scoped>
  .country-field {
    width: 230px;
  }

  .country-field:not(:last-child) {
    margin-right: 20px;
  }

  .wowza {
    margin-bottom: 20px;
  }

  .wowza p:last-child {
    background: #383b40;
    margin-top: 10px;
    padding: 15px;
  }

  .btn-container {
    visibility: hidden;
    margin-bottom: 20px;
  }

  .check-wrapper {
    margin-bottom: 30px;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }

  .localization-warning-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #F2C94C;
    border-radius: 4px;
    padding: 10px;
    margin-left: 2px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .localization-warning {
    flex: 0.98;
    font-size: 16px;
    color: #F2C94C;
    padding-left: 8px;
  }

  .master-accs {
    list-style-type: disc;
    padding-left: 25px;
    margin-top: 5px;
  }

  .toggle-wrapper {
    align-items: center;
    display: flex;
    margin-right: 30px;
    width: 17vw;
  }

  .toggle-title {
    margin-right: 10px;
  }

  .permission {
    margin-bottom: 30px;
  }

  .error-credentials {
    color: #fb3951;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .themes-wrapper {
    margin-bottom: 30px;
  }

  .check-themes-wrapper {
    display: flex;
  }

  .check-theme-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 10px;
  }

  @media all and (max-width: 1320px) {
    .flex {
      flex-wrap: wrap;
    }

    .country-field {
      margin-right: 0;
      width: 100%;
    }

    .country-field:not(:last-child) {
      margin-right: 0;
    }
  }
</style>
